.order {
    background-color: #f9f9f9;
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 4rem;
  }
  
  .content {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .content h2 {
    color: #c41e3a;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  /* Styles for CheckoutForm */
  .content :global(.checkout-form) {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .content :global(.form-group) {
    margin-bottom: 1.5rem;
  }
  
  .content :global(.form-group label) {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .content :global(.form-group input),
  .content :global(.form-group select),
  .content :global(.form-group textarea) {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
  }
  
  .content :global(.form-group input:focus),
  .content :global(.form-group select:focus),
  .content :global(.form-group textarea:focus) {
    outline: none;
    border-color: #c41e3a;
  }
  
  .content :global(.form-group textarea) {
    height: 100px;
    resize: vertical;
  }
  
  .content :global(.error) {
    color: #c41e3a;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    display: block;
  }
  
  .content :global(.warning) {
    background-color: #fff3cd;
    color: #856404;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  
  .content :global(.checkout-form button) {
    background-color: #c41e3a;
    color: #ffffff;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    margin-top: 1rem;
  }
  
  .content :global(.checkout-form button:hover) {
    background-color: #a51a30;
  }
  
  .content :global(.checkout-form button:disabled) {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  @media (max-width: 768px) {
    .order {
      padding-top: 0.5rem;
      padding-bottom: 2rem;
    }

    .content {
      padding: 0 15px;
    }
  
    .content :global(.checkout-form) {
      padding: 1.5rem;
    }
  
    .content h2 {
      font-size: 2rem;
    }
  }