.button {
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .primary {
    background-color: var(--color-primary);
    color: var(--color-white);
  }
  
  .primary:hover {
    background-color: var(--color-primary-dark);
  }
  
  .secondary {
    background-color: var(--color-secondary);
    color: var(--color-white);
  }
  
  .secondary:hover {
    opacity: 0.9;
  }
  
  .small {
    font-size: var(--font-size-small);
    padding: var(--spacing-small) var(--spacing-medium);
  }
  
  .medium {
    font-size: var(--font-size-base);
    padding: var(--spacing-medium) var(--spacing-large);
  }
  
  .large {
    font-size: var(--font-size-large);
    padding: var(--spacing-large) calc(var(--spacing-large) * 1.5);
  }