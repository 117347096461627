.heroAnchor {
  display: block;
  height: 80px; /* Adjust this to match your header height */
  margin-top: -80px; /* Negative value of the height */
  visibility: hidden;
}

.hero {
  background-image: url('../../assets/background.webp');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: calc(100vh - 80px); /* Subtract header height */
  min-height: 500px; /* Ensure minimum height on smaller screens */
}

.heroOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6));
}

.heroContent {
  position: relative;
  z-index: 1;
  color: #ffffff;
  text-align: center;
  max-width: 800px;
  padding: 0 20px;
}

.heroContent h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.heroContent p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.ctaContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.ctaButton,
.learnMoreButton {
  display: inline-block;
  padding: 0.8rem 2rem;
  font-size: 1.1rem;
  font-weight: 700;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.ctaButton {
  background-color: var(--color-primary);
  color: #fff;
}

.ctaButton:hover {
  background-color: var(--color-primary-dark);
}

.learnMoreButton {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
}

.learnMoreButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
  .heroContent h1 {
    font-size: 2.5rem;
  }

  .heroContent p {
    font-size: 1rem;
  }

  .ctaContainer {
    flex-direction: column;
    align-items: center;
  }
  
  .ctaButton,
  .learnMoreButton {
    padding: 0.6rem 1.5rem;
    font-size: 1rem;
    width: 40%;
  }
}