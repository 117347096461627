/* About.module.css */
 .about {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #f9f9f9;
  }
  
  .content {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
  }
  
  .content h2 {
    font-size: 2.5rem;
    color: #c41e3a;
    margin-bottom: 1.5rem;
  }
  
  .content p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #333333;
  }
  
  @media (max-width: 768px) {
    .about {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  
    .content h2 {
      font-size: 2rem;
    }
  
    .content p {
      font-size: 1rem;
    }
  }