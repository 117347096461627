.productInfo {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.productCard h3 {
  font-size: 1.2rem;
  margin-top: 0.4rem;
  margin-bottom: 0.3rem;
  color: #333333;
}

.shortDescription {
  font-weight: 600;
  color: #444444;
  margin-bottom: 0.3rem;
  font-size: 0.9rem;
}

.material,
.dimensions {
  font-size: 0.8rem;
  color: #666666;
  margin-bottom: 0.2rem;
}

.description {
  font-size: 0.85rem;
  color: #555555;
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.productPrice {
  font-weight: 700;
  color: #c41e3a;
  font-size: 1rem;
  margin-top: auto;
  margin-bottom: 0.5rem;
}

.orderButton {
  width: 100%;
  padding: 0.6rem;
  background-color: #c41e3a;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.orderButton:hover {
  background-color: #a51a30;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

.closeButton {
  float: right;
  font-size: 1.5rem;
  cursor: pointer;
}

.modalImage {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.products {
  padding-bottom: 2rem;
  margin: 0;
  background-color: #f9f9f9;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.content h2 {
  font-size: 2.5rem;
  color: #c41e3a;
  text-align: center;
  margin-bottom: 2rem;
}

.productGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
}

.productCard {
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.productCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 300px; /* Adjust as needed */
  overflow: hidden;
}

.productImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures the image covers the area without distortion */
}

.productInfo {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.productCard h3 {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  color: #333333;
}

.shortDescription {
  font-weight: 600;
  color: #444444;
  margin-bottom: 0.5rem;
}

.material,
.dimensions {
  font-size: 0.85rem;
  color: #666666;
  margin-bottom: 0.3rem;
}

.description {
  font-size: 0.9rem;
  color: #555555;
  margin-bottom: 1rem;
  flex-grow: 1;
}

.productPrice {
  display: block;
  font-weight: 700;
  color: #c41e3a;
  font-size: 1.1rem;
  margin-top: auto;
  margin-bottom: 1rem;
}

.selected {
  border: 2px solid #c41e3a;
}

.orderButton {
  width: 100%;
  padding: 0.8rem;
  background-color: #c41e3a;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.orderButton:hover {
  background-color: #a51a30;
}

@media (max-width: 768px) {
  .products {
    padding-bottom: 1rem;
  }

  .content h2 {
    font-size: 2rem;
  }

  .productGrid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .productInfo {
    padding: 1.2rem;
  }
}

@media (max-width: 480px) {
  .productGrid {
    grid-template-columns: 1fr;
  }
}

.modalImageContainer {
  position: relative;
  width: 100%;
  height: 500px; /* Adjust as needed */
  overflow: hidden;
}
