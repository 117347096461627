.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 80px; /* Should match your header height */
  overflow: hidden;
}

.title {
  text-align: center;
  margin: 20px 0;
}

.pdfContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pdfContainer object {
  flex: 1;
  width: 100%;
  border: none;
}

/* Remove these styles as they're not needed for the object tag */
.pageInfo,
.pdfContainer > div,
.pdfContainer canvas {
  display: none;
}
