.footer {
    background-color: #1a535c;
    color: #ffffff;
    padding: 2rem 0;
    text-align: center;
  }
  
  .socialIcons {
    margin-bottom: 1rem;
  }
  
  .socialIcons a {
    color: #ffffff;
    font-size: 1.5rem;
    margin: 0 0.5rem;
    transition: opacity 0.3s ease;
  }
  
  .socialIcons a:hover {
    opacity: 0.8;
  }
  
  .copyright {
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }
  
  .footerNav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .footerNav a {
    color: #ffffff;
    text-decoration: none;
    margin: 0 1rem;
    font-size: 0.9rem;
    transition: opacity 0.3s ease;
  }
  
  .footerNav a:hover {
    opacity: 0.8;
  }
  
  @media (max-width: 768px) {
    .footerNav {
      flex-direction: column;
    }
  
    .footerNav a {
      margin: 0.5rem 0;
    }
  }