.slider {
  position: relative;
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.7);
  color: #666;
  width: 32px;
  height: 32px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 2;
}

.slider:hover .arrow {
  opacity: 1;
}

.arrow:hover {
  background: rgba(255, 255, 255, 0.9);
  color: #333;
}

.leftArrow {
  left: 10px;
}

.rightArrow {
  right: 10px;
}

.dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 6px;
  padding: 5px 10px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.slider:hover .dots {
  opacity: 1;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ccc;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dot:hover {
  background: #666;
}

.activeDot {
  background: #333;
  width: 8px;
  height: 8px;
}
