.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background-color: var(--color-white);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoLink {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo {
  height: 40px;
  width: auto;
  margin-right: 0.5rem;
}

.logoText {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-primary);
}

.rightContent {
  display: flex;
  align-items: center;
}

.nav {
  display: flex;
  align-items: center;
}

.navList {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
}

.navList li:not(:last-child) {
  margin-right: 1.5rem;
}

.navList a {
  color: var(--color-text);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  cursor: pointer;
}

.navList a:hover {
  color: var(--color-primary);
}

.comandaButton {
  margin-left: 1.5rem;
}

.menuToggle {
  display: none;
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .rightContent {
    flex-grow: 1;
    justify-content: flex-end;
  }

  .nav {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--color-white);
    flex-direction: column;
    align-items: flex-end;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: none;
  }

  .nav.open {
    display: flex;
  }

  .navList {
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  .navList li {
    margin: 0.5rem 1rem;
  }

  .comandaButton {
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  .menuToggle {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 1rem;
  }

  .menuToggle span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: var(--color-text);
    margin: 5px 0;
    transition: all 0.3s ease;
  }

  .open .menuToggle span:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .open .menuToggle span:nth-child(2) {
    opacity: 0;
  }

  .open .menuToggle span:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
  
  .navList li:not(:last-child) {
    margin-right: 1rem;
  }
 
}