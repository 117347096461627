.packagingAndDelivery {
  background-color: #f9f9f9;
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.packagingAndDelivery h2 {
  margin-top: 0;
  color: #c41e3a;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
}

.content {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.packaging, .delivery {
  flex: 1;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.packaging h3, .delivery h3 {
  color: #c41e3a;
  margin-bottom: 1rem;
}

.packaging p, .delivery p {
  font-size: 0.9rem;
  color: #333;
  line-height: 1.6;
}


@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }
}