.checkoutForm {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.formGroup {
  margin-bottom: 1.5rem;
}

.formGroup label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #333;
}

.formGroup input,
.formGroup select,
.formGroup textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.formGroup input:focus,
.formGroup select:focus,
.formGroup textarea:focus {
  outline: none;
  border-color: #c41e3a;
}

.formGroup textarea {
  height: 100px;
  resize: vertical;
}

.fieldDescription {
  font-size: 0.875rem;
  color: #666;
  margin-top: 0.25rem;
}

.error {
  color: #c41e3a;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.warning {
  background-color: #fff3cd;
  color: #856404;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.submitButton {
  background-color: #c41e3a;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 1rem;
}

.submitButton:hover {
  background-color: #a51a30;
}

.submitButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.additionalInfo {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #ddd;
}

.additionalInfo h3 {
  color: #c41e3a;
  margin-bottom: 0.5rem;
}

.additionalInfo p, .additionalInfo ul {
  font-size: 0.9rem;
  color: #666;
}

.packagingDeliverySummary {
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.packagingDeliverySummary h3 {
  color: #c41e3a;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.packagingDeliverySummary p {
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.moreInfoLink {
  color: #c41e3a;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: bold;
}

.moreInfoLink:hover {
  text-decoration: underline;
}