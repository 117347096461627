.process {
    padding-bottom: 4rem;
    margin: 0;
    background-color: #f9f9f9;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .title {
    font-size: 2.5rem;
    color: #c41e3a;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .stepsContainer {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 1rem;
  }
  
  .step {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
  }
  
  .stepContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem 1rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .step:hover .stepContent {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .iconContainer {
    background-color: #c41e3a;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .icon {
    color: #ffffff;
    width: 32px;
    height: 32px;
  }
  
  .stepTitle {
    font-size: 1.1rem;
    color: #c41e3a;
    margin-bottom: 1rem;
  }
  
  .stepDescription {
    font-size: 0.9rem;
    color: #666666;
    line-height: 1.6;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  
  .arrowContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
  }
  
  .arrowHorizontal,
  .arrowVertical {
    color: #c41e3a;
    width: 24px;
    height: 24px;
  }
  
  .arrowVertical {
    display: none;
  }
  
  @media (max-width: 1024px) {
    .stepsContainer {
      flex-wrap: wrap;
    }
  
    .step {
      flex-basis: calc(50% - 2rem);
    }
  
    .arrowContainer {
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    .process {
      padding-bottom: 2rem;
    }
  
    .title {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  
    .stepsContainer {
      flex-direction: column;
      align-items: center;
    }
  
    .step {
      width: 100%;
      max-width: 400px;
    }
  
    .arrowContainer {
      display: flex;
      margin: 1rem 0;
    }
  
    .arrowHorizontal {
      display: none;
    }
  
    .arrowVertical {
      display: block;
    }
  }