.testimonials {
    padding-top: 2rem;
    padding-bottom: 4rem;
    margin: 0;
    background-color: #1a535c;
    color: #ffffff;
  }
  
  .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .content h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .testimonialGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .testimonial {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 2rem;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .testimonial:hover {
    transform: translateY(-5px);
  }
  
  .testimonial p {
    font-style: italic;
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  
  .author {
    font-weight: 700;
    text-align: right;
  }
  
  @media (max-width: 768px) {
    .testimonials {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    .content h2 {
      font-size: 2rem;
    }
  
    .testimonial {
      padding: 1.5rem;
    }
  
    .testimonial p {
      font-size: 1rem;
    }
  }