:root {
    /* Colors */
    --color-primary: #c41e3a;
    --color-primary-dark: #a51a30;
    --color-secondary: #1a535c;
    --color-text: #333333;
    --color-text-light: #666666;
    --color-background: #f9f9f9;
    --color-white: #ffffff;
  
    /* Typography */
    --font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    --font-size-base: 16px;
    --font-size-small: 0.875rem;
    --font-size-large: 1.25rem;
    --font-weight-normal: 400;
    --font-weight-semibold: 500;
    --font-weight-bold: 600;
  
    /* Spacing */
    --spacing-small: 0.5rem;
    --spacing-medium: 1rem;
    --spacing-large: 2rem;
  
    /* Border */
    --border-radius: 4px;
  }