.successPage {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 80px);
    background-color: #f9f9f9;
    padding: 2rem;
  }
  
  .successContent {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 600px;
    width: 100%;
    text-align: center;
  }
  
  .checkIcon {
    color: #4CAF50;
    font-size: 4rem;
    margin-bottom: 1rem;
  }
  
  .successContent h1 {
    color: #333;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  
  .mainMessage {
    font-size: 1.2rem;
    color: #4CAF50;
    margin-bottom: 1.5rem;
  }
  
  .orderInfo {
    background-color: #f0f0f0;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .orderId {
    font-weight: bold;
    font-size: 1.1rem;
    word-break: break-all;
  }
  
  .nextSteps {
    text-align: left;
    margin-bottom: 1.5rem;
  }
  
  .nextSteps h2 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  
  .nextSteps ol {
    padding-left: 1.5rem;
  }
  
  .nextSteps li {
    margin-bottom: 0.5rem;
  }
  
  .contact {
    margin-bottom: 1.5rem;
  }
  
  .contact a {
    color: #c41e3a;
    text-decoration: none;
  }
  
  .contact a:hover {
    text-decoration: underline;
  }
  
  .backButton {
    display: inline-block;
    background-color: #c41e3a;
    color: #ffffff;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .backButton:hover {
    background-color: #a51a30;
  }
  
  @media (max-width: 768px) {
    .successPage {
      padding: 1rem;
    }
    
    .successContent {
      padding: 1.5rem;
    }
    
    .successContent h1 {
      font-size: 1.5rem;
    }
    
    .mainMessage {
      font-size: 1rem;
    }
    
    .nextSteps h2 {
      font-size: 1.2rem;
    }
  }